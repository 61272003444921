/* eslint-disable import/extensions, import/no-unresolved, import/no-unassigned-import, import/no-extraneous-dependencies */
import React, { Fragment } from 'react';
import { Location } from '@reach/router';
import PropTypes from 'prop-types';
import SideNav from 'components/SideNav';
import Main from './Main';
import TopNav from '../TopNav';
import Footer from '../Footer';
import Query from '../../queries/navQuery';
import Content from './Content';
import BrandThemeProvider from './BrandThemeProvider';

const Layout = ({ children, pageContext }) => (
  <Query
    render={menuItems => (
      <BrandThemeProvider>
        <TopNav />
        <Main>
          <Content>{children}</Content>
          <Footer />
        </Main>
        {/** SideNav must come after main for z-index */}
        <Location>
          {({ location }) => (
            <SideNav
              location={location}
              pageContext={pageContext}
              menuItems={menuItems}
            />
          )}
        </Location>
      </BrandThemeProvider>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape({
    hash: PropTypes.string,
    host: PropTypes.string,
    hostname: PropTypes.string,
    href: PropTypes.string,
    origin: PropTypes.string,
    pathname: PropTypes.string,
    port: PropTypes.string,
    protocol: PropTypes.string,
    search: PropTypes.string,
  }),
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.shape({
      name: PropTypes.string,
      menu: PropTypes.string,
    }),
  }),
};

export default Layout;
/* eslint-enable import/extensions, import/no-unresolved, import/no-unassigned-import */
