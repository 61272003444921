/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout/IntroLayout'; // eslint-disable-line
import SEO from 'components/Seo'; // eslint-disable-line
import DocsIllustration from 'components/DocsIllustration';
import { Link } from 'gatsby';
import { useComponents } from '../utils/multiBrandedTheme';

const NotFoundPage = ({ pageContext }) => {
  const { Box, Heading, Paragraph, Button } = useComponents();
  return (
    <Layout pageContext={pageContext}>
      <SEO title="404: Not found" />
      <Box
        color="primaryBase"
        textAlign="center"
        width="450px"
        mx="auto"
        py={11}
      >
        <DocsIllustration name="Fourofour" />
        <Heading level={2} my={5} fontFamily="primary" color="colorGrey80">
          Houston we’ve a problem!
        </Heading>
        <Paragraph mb={9} fontFamily="primary" color="colorGrey80">
          This page was not found. You may have mistyped the adress or the page
          may have moved.
        </Paragraph>
        <Button as={Link} to="/">
          Go to homepage
        </Button>
      </Box>
    </Layout>
  );
};

NotFoundPage.propTypes = {
  pageContext: PropTypes.shape({
    frontmatter: PropTypes.shape({
      name: PropTypes.string,
      menu: PropTypes.string,
      path: PropTypes.string,
    }),
  }),
};

export default NotFoundPage;
